import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const PrizeGrid = ({ setOpen }) => {
  return (
    <div className='py-20 lg:py-[7.5rem] bg-blue background-blue-repeated px-gutter text-[0.9375rem] lg:text-[1.5rem] '>
      <div className='text-center mb-20 lg:mb-[7.5rem]'>
        <h2 className='text-[2.5rem] lg:text-[6.25rem] uppercase font-obviously-wide mb-[1.25rem] lg:mb-[2.5rem]'>
          Prizes
        </h2>
        <p
          onClick={() => setOpen(true)}
          className='text-[1rem] lg:text-[1.75rem] cursor-pointer underline underline-offset-4 w-fit mx-auto leading-[120%]'
        >
          View Terms & Conditions
        </p>
      </div>
      <div className='grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-[0.8125rem] lg:gap-x-7 gap-y-10 leading-[120%]'>
        {/* prize item */}
        <div>
          <StaticImage src='../../images/prizes/te-arai.jpg' alt='Te Arai' />
          <div>
            <p className='mt-3'>
              Play Te Arai Links w/ Dan Carter + Leon MacDonald
            </p>
            <p>For 2 people, includes lunch </p>
          </div>
        </div>
        {/* prize item */}
        <div>
          <StaticImage
            src='../../images/prizes/fortnite.jpg'
            alt='Rieko Ioane'
          />
          <div>
            <p className='mt-3'>Fortnite party w/ Rieko Ioane and other ABs</p>
            <p>2 hour online Battle Royale</p>
          </div>
        </div>
        {/* prize item */}
        <div>
          <StaticImage
            src='../../images/prizes/ellerslie.jpg'
            alt='ellerslie'
          />
          <div>
            <p className='mt-3'>Cuvee on Melbourne Cup Day 2024</p>
            <p>Table for 10 @ Ellerslie Racecourse</p>
          </div>
        </div>
        {/* prize item */}
        <div>
          <StaticImage src='../../images/prizes/maui.jpg' alt='maui' />
          <div>
            <p className='mt-3'>Maui Campervan 5-day Road Trip</p>
            <p>Courtesy of THL</p>
          </div>
        </div>
        {/* prize item */}
        <div>
          <StaticImage
            src='../../images/prizes/neatmeat.jpg'
            alt='Neat Meat Butchers Club'
          />
          <div>
            <p className='mt-3'>Neat Meat Butchers Club</p>
            <p>Half cattle beast RRP$2,000</p>
          </div>
        </div>
        {/* prize item */}
        <div>
          <StaticImage src='../../images/prizes/505.jpg' alt='505' />
          <div>
            <p className='mt-3'>505 Construction Ltd</p>
            <p>Labour + vehicle use up to $2,500</p>
          </div>
        </div>

        {/* prize item */}
        <div>
          <StaticImage
            src='../../images/prizes/ali-williams.jpg'
            alt='ali-williams'
          />
          <div>
            <p className='mt-3'>Heli-fishing on Great Barrier Island</p>
            <p>4 guests w/ Ali Williams (food + bev included)</p>
          </div>
        </div>
        {/* prize item */}
        <div>
          <StaticImage
            src='../../images/prizes/traeger.jpg'
            alt='traeger-grill'
          />
          <div>
            <p className='mt-3'>Traeger Ironwood 650 Pellet Grill</p>
            <p>Courtesy of Bracewell Construction</p>
          </div>
        </div>

        {/* prize item */}
        <div>
          <StaticImage src='../../images/prizes/anker757.jpg' alt='anker757' />
          <div>
            <p className='mt-3'>Anker 757 PowerHouse mobile generator</p>
            <p>Courtesy of Directed Electronics</p>
          </div>
        </div>
        <div>
          <StaticImage
            src='../../images/prizes/dan-hooker.jpg'
            alt='dan-hooker'
          />
          <div>
            <p className='mt-3'>
              Rum Racing Sailing Experience for 5 w/ Dan Hooker
            </p>
            <p>Courtesy of D3 Development</p>
          </div>
        </div>
        <div>
          <StaticImage
            src='../../images/prizes/scenic-flight.jpg'
            alt='scenic-flight'
          />
          <div>
            <p className='mt-3'>Scenic Auckland Heli flight for 4</p>
            <p>Courtesy of Loan Market Central</p>
          </div>
        </div>
        <div>
          <StaticImage src='../../images/prizes/crossfit.jpg' alt='crossfit' />
          <div>
            <p className='mt-3'>Crossfit Auckland</p>
            <p>6 month membership, AKL CBD</p>
          </div>
        </div>
        <div>
          <StaticImage
            src='../../images/prizes/ab-experience.jpg'
            alt='ab-experience'
          />
          <div>
            <p className='mt-3'>Private tour of the All Blacks experience</p>
            <p>Up to 26 people guided by a current All Black</p>
          </div>
        </div>
        <div>
          <StaticImage src='../../images/prizes/bauhaus.jpg' alt='bauhaus' />
          <div>
            <p className='mt-3'>$1,000 Bauhaus furniture voucher</p>
            <p>Courtesy of Bauhaus</p>
          </div>
        </div>
        <div>
          <StaticImage
            src='../../images/prizes/eden-park.jpg'
            alt='eden-park'
          />
          <div>
            <p className='mt-3'>Eden Park skills & drills session</p>
            <p>Plus All Blacks dressing room tour</p>
          </div>
        </div>
        <div>
          <StaticImage src='../../images/prizes/mudbrick.jpg' alt='mudbrick' />
          <div>
            <p className='mt-3'>Mudbrick Dining Experience for 2</p>
            <p>2 course dinner + bottle of Reserve Mudbrick wine</p>
          </div>
        </div>
        <div>
          <StaticImage
            src='../../images/prizes/orthodontics.jpg'
            alt='orthodontics'
          />
          <div>
            <p className='mt-3'>
              Specialist orthodontic consultation + customised mouthguard
            </p>
            <p>Courtesy of Dr Kimberley Timmins</p>
          </div>
        </div>
        <div>
          <StaticImage
            src='../../images/prizes/michael-jones.jpg'
            alt='michael-jones'
          />
          <div>
            <p className='mt-3'>Signed Michael Jones Sketch</p>
            <p>Courtesy of Ponsonby Rugby</p>
          </div>
        </div>
        <div>
          <StaticImage
            src='../../images/prizes/fit-bodyz.jpg'
            alt='fit-bodyz'
          />
          <div>
            <p className='mt-3'>F.I.T Bodyz gym </p>
            <p>6 month membership, Mt Albert</p>
          </div>
        </div>
        <div>
          <StaticImage
            src='../../images/prizes/rugbyball.jpg'
            alt='adidas rugby ball'
          />
          <div>
            <p className='mt-3'>Original leather Adidas Rugby ball</p>
            <p>Signed by the 1993 All Blacks team</p>
          </div>
        </div>
        <div>
          <StaticImage src='../../images/prizes/sawmill.jpg' alt='sawmill' />
          <div>
            <p className='mt-3'>Sawmill Brewery Smoko Room</p>
            <p>$250 Food & Beverage Voucher</p>
          </div>
        </div>
        <div>
          <StaticImage src='../../images/prizes/ecoya.jpg' alt='ecoya' />
          <div>
            <p className='mt-3'>4 x ECOYA Home Fragrance Gift Boxes</p>
            <p>1 per winner</p>
          </div>
        </div>

        <div>
          <StaticImage
            src='../../images/prizes/nutrideer.jpg'
            alt='nutrideer'
          />
          <div>
            <p className='mt-3'>Nutrideer Premium Pet powder supplements</p>
            <p>3 Month supply for optimal pet health</p>
          </div>
        </div>
        <div>
          <StaticImage
            src='../../images/prizes/hello-beasty.jpg'
            alt='nutrideer'
          />
          <div>
            <p className='mt-3'>Hello Beasty dining experience</p>
            <p>$250 food & beverage voucher</p>
          </div>
        </div>
        <div>
          <StaticImage
            src='../../images/prizes/norsewear.jpg'
            alt='norsewear'
          />
          <div>
            <p className='mt-3'>Norsewear - Premium NZ Merino apparel</p>
            <p>$250 voucher</p>
          </div>
        </div>
        <div>
          <StaticImage
            src='../../images/prizes/triumph-disaster.jpg'
            alt='triumph-disaster'
          />
          <div>
            <p className='mt-3'>Triumph & Disaster 'Stash Box' </p>
            <p>Shaving + grooming kit</p>
          </div>
        </div>
        <div>
          <StaticImage
            src='../../images/prizes/skateboard.jpg'
            alt='Young Guns Skate School Skateboard'
          />
          <div>
            <p className='mt-3'>
              Young Guns Skate School Skateboard, Pad set, Tech Deck + voucher
            </p>
          </div>
        </div>
        <div>
          <StaticImage
            src='../../images/prizes/fantl-sport.jpg'
            alt='Fantl-sport'
          />
          <div>
            <p className='mt-3'>Fantl Sport $250 voucher</p>
          </div>
        </div>
      </div>

      {/* -------------------- no image prizes -------------------- */}

      {/* prize item */}
      {/* <div className='mt-[1.75rem] lg:mt-[2.5rem] grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-[0.8125rem] lg:gap-x-7 gap-y-5 '>
        <p className='mt-3'>Nulla deserunt dolor eiusmod officia mollit.</p>
      </div> */}
    </div>
  );
};

export default PrizeGrid;
