import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

const Banner = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, delay: 0.2 }}
      className='bg-blue w-full min-h-[100dvh] background-blue flex items-center justify-center px-[0.75rem] lg:px-[6.25rem] py-[8.75rem]'
    >
      <StaticImage src='../../images/hero-text.png' alt='hero text' />
    </motion.div>
  );
};

export default Banner;
