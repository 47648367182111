import React, { useState } from 'react';
import Layout from '@components/layout';
import { PageMeta } from '@components/pageMeta';
import PrizeGrid from '../components/donationForm/prizeGrid';
import TermsPopup from '../components/donationForm/termsPopup';
import PortalModal from '@components/UI/portalModal';
import Banner from '@components/donationForm/banner';
import InfoSection from '../components/donationForm/infoSection';

const IndexPage = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  return (
    <Layout setOpen={setOpen}>
      <PageMeta />
      <Banner />
      <InfoSection />
      <PrizeGrid setOpen={setOpen} />
      {/* terms modal starts */}
      <PortalModal isShowing={open}>
        <TermsPopup handleClose={handleClose} />
      </PortalModal>
    </Layout>
  );
};

export default IndexPage;
