import React from 'react';
import { AnimatePresence } from 'framer-motion';

import Cross from '@svg/cross.svg';

const TermsPopup = ({ handleClose }) => {
  return (
    <AnimatePresence>
      <div className='bg-white my-10 w-full h-full self-center  lg:max-h-[90dvh] max-w-[66.75rem] mx-auto  relative  overflow-y-scroll '>
        <div
          onClick={handleClose}
          className='absolute top-5 right-5 lg:right-20 cursor-pointer z-50  rounded-full size-[2.5rem] lg:size-[3rem] '
        >
          <Cross className='w-full absolute top-1/2 left-1/2  -translate-y-1/2 -translate-x-1/2' />
        </div>
        <div className='px-4 py-[5rem]  lg:py-[7.375rem]  lg:px-20  '>
          <h1 className='text-[1.75rem] lg:text-[3.75rem] font-obviously-wide uppercase text-center pb-10 lg:pb-32'>
            Terms & Conditions
          </h1>

          <div className='mb-10 px-4 text-[0.75rem] lg:text-[1rem] leading-[140%]'>
            <div className='pb-10'>
              <p className='font-bold  mb-4'>
                {' '}
                This raffle is organised and administered by Ben Hurst
              </p>

              <a href='tel:+021817004' className='block'>
                Phone: 021 817 004
              </a>
              <br />
              <a href='mailto:ben@elcgroup.co.nz' className='block'>
                Email: ben@elcgroup.co.nz
              </a>
            </div>

            <ol className='list-decimal space-y-6 	'>
              <h3 className='font-bold  mb-4'>Entry requirements</h3>
              <li>The cost of each entry into the Raffle is $25 per ticket.</li>
              <li>
                You can purchase a raffle ticket for $25 at
                ponsonbyrugbyraffle.co.nz
                {/* wwww.live.co.nz */}
              </li>
              <li>
                The closing time for purchasing tickets is 5pm on Friday May
                3rd. Tickets purchased after this time will not be entered into
                the draw and monies will be treated as a donation.
              </li>
              <li>
                The raffle organiser respects and is committed to protecting
                your privacy. The personal information you provide will remain
                private and will only be used for the purposes outlined here.
              </li>
              <h3 className='font-bold mb-4'>Prizes</h3>
              <li>The prizes are:</li>
              <ol
                // type="a"
                // style={{ listStyleType: "lower-alpha" }}
                className='pl-6 space-y-2 list-disc'
              >
                <li>
                  Play Te Arai Links w/ Dan Carter + Leon MacDonald - For 2
                  people, includes lunch
                </li>
                <li>
                  Fortnite party w/ Reiko Ioane and other ABs - 2 hour online
                  Battle Royale
                </li>
                <li>
                  Cuvee on Melbourne Cup Day 2024 - Table for 10 @ Ellerslie
                  Racecourse
                </li>
                <li>Maui Campervan 5-day Road Trip</li>
                <li>iPhone 15 Pro 256GB</li>
                <li>Neat Meat Butchers Club - Half cattle beast RRP$2,000</li>
                <li>
                  505 Construction Ltd - Labour + vehicle use up to $2,500
                </li>
                <li>
                  Heli-fishing on Great Barrier Island - 4 guests w/ Ali
                  Williams (all inclusive)
                </li>
                <li>Traeger Ironwood 650 Pellet Grill</li>
                <li>Anker 757 PowerHouse mobile generator</li>
                <li>Rum Racing Sailing Experience for 5 w/ Dan Hooker</li>
                <li>Scenic Auckland Heli flight for 4</li>
                <li>Crossfit Auckland - 6 month membership, AKL CBD</li>
                <li>
                  Private tour of the All Blacks experience - Up to 26 people
                  guided by a current All Black
                </li>
                <li>$1,000 Bauhaus furniture voucher</li>
                <li>Oyster Inn (Waiheke) - One nights accom + lunch for 2 </li>
                <li>
                  Eden Park skills & drills session - Plus All Blacks dressing
                  room tour
                </li>
                <li>
                  Mudbrick Dining Experience for 2 - 2 course dinner + bottle of
                  Reserve Mudbrick wine
                </li>
                <li>
                  Specialist orthodontic consultation + customised mouthguard
                </li>
                <li>Signed Michael Jones Sketch</li>
                <li>
                  Case of Mt Difficulty Single Vintage - 6 bottles of Target
                  Valley Pinot Noir 2016
                </li>
                <li>F.I.T Bodyz gym - 6 month membership, Mt Albert</li>
                <li>
                  Original leather Adidas Rugby ball - Signed by the 1993 All
                  Blacks team
                </li>
                <li>Signed Auckland NPC rugby jersey</li>
                <li>
                  Sawmill Brewery Smoko Room - $250 Food & Beverage Voucher
                </li>
                <li>4 x ECOYA Home Fragrance Gift Boxes - 1 per winner </li>
                <li>
                  Nutrideer Premium Pet powder supplements - 3 Month supply for
                  optimal pet health
                </li>
                <li>
                  Hello Beasty dining experience - $250 food & beverage voucher
                </li>
                <li>Norsewear - Premium NZ Merino apparel - $250 voucher</li>
                <li>Triumph & Disaster 'Stash Box' - Shaving + grooming kit</li>
                <li>
                  Young Guns Skate School Skateboard, Pad set, Tech Deck +
                  voucher
                </li>
                <li>Fantl Sport $250 voucher</li>
              </ol>

              <h3 className='font-bold mb-4'>Winners</h3>
              <li>
                The winners will be drawn under supervision by Ben Hurst on
                Friday, May 3rd.
              </li>
              <li>
                The winning entries will be selected from all entries received
                by the closing time and prizes will be awarded in order of
                selection, subject to these terms. Only tickets for which full
                payment has been received will be eligible to win a prize.
              </li>
              <li>
                The winner will be notified by either phone or mail within 7
                working days of the closing date either by email.
              </li>
              <li>
                In the event that we cannot contact any winner within 7 days
                after the draw, the prize will be redrawn and awarded to a new
                winner.
              </li>
              <li>
                The person named on the raffle ticket entry will be the only
                person entitled to the prize. To the extent there is any
                confusion as to the correct person, Mr Hurst will make a binding
                determination in his sole discretion.
              </li>
              <li>
                If no contact details are given in conjunction with any ticket
                purchased, a new winner will be selected on the day of the draw.
              </li>
              <li>
                The prizes are non-transferable and only consist of what is
                listed above.
              </li>
              <li>
                If a prize cannot be paid or delivered (as applicable) using the
                details you have provided when purchasing a ticket, after 1
                month following the date of the draw, this prize will be
                forfeited.
              </li>
              <li>
                It is Mr Hurst's decision as to any matters arising is final and
                no correspondence will be entered into.
              </li>
              <li>
                Unless explicitly stipulated otherwise in the prize description
                above, it is the sole responsibility of the winner to arrange
                and cover all expenses related to travel and accommodation
                necessary to redeem the prize. This includes but is not limited
                to transportation costs, lodging expenses, meals, and any other
                incidental costs associated with claiming the prize. The
                organiser of the raffle shall not be held liable for any
                expenses incurred by the winner in connection with claiming the
                prize.
              </li>
              <h3 className='font-bold  mb-4'>Further Terms and Conditions</h3>
              <li>
                By entering the Raffle, you agree to be bound by these Terms and
                Conditions. Any Raffle entry and participation instructions form
                part of these Terms and Conditions. Any entries not complying
                with these Terms and Conditions will not be valid.
              </li>
              <li>
                Mr Hurst reserves the right to reject your entry and/or award
                any prize to an alternative winner if Mr Hurst has grounds to
                believe that you have breached any of these Terms and
                Conditions, acted fraudulently or illegally, or on other
                reasonable grounds and Mr Hurst shall have no liability to you
                for any loss or damage arising from exercising such rights.
              </li>
              <li>
                Mr Hurst reserves the right to modify, cancel, terminate or
                suspend the Raffle in whole or in part in the Chairman's sole
                discretion.
              </li>
              <li>
                Mr Hurst will not be liable for: Raffle tickets, entries,
                communications or prizes which are lost, stolen, delayed,
                damaged or (in the case of tickets or entries) from which the
                prize winner or entrant cannot be identified; any late bank
                payments; Mr Hurst's failure or inability to contact you and/or
                award any prize due to any errors, omissions or inaccuracies in
                the contact or bank details you have provided or your failure to
                update these if they change; or loss or damage incurred by you
                in connection with your participation in the Raffle or your use
                of any prize.
              </li>
              <li>
                Mr Hurst reserves the right to amend, withdraw or terminate the
                raffle or alter the raffle at any time without prior notice.
              </li>
              <li>
                These terms and conditions are governed by New Zealand law and
                entrants submit to the exclusive jurisdiction of the New Zealand
                courts.
              </li>
              <li>
                Mr Hurst's decision in all matters relating to the Raffle is
                final.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </AnimatePresence>
  );
};

export default TermsPopup;
