import React, { useRef, useState, useEffect } from 'react';
import { useCountUp } from 'react-countup';
import Button from '../UI/button';

const InfoSection = () => {
  const [donationAmount, setDonationAmount] = useState(21416);

  // useEffect(() => {
  //   const fetchDonationAmount = async () => {
  //     const res = await fetch('/.netlify/functions/get-donation-amount');
  //     const data = await res.json();
  //     setDonationAmount(data);
  //   };

  //   fetchDonationAmount();
  // }, []);

  const donationAmountRef = useRef(donationAmount);

  useEffect(() => {
    donationAmountRef.current = donationAmount;
    update(donationAmount);
  }, [donationAmount]);

  const countUpRef = useRef();

  const { start, reset, update } = useCountUp({
    ref: countUpRef,
    start: 0,
    end: 21416,
    duration: 2,
    separator: ',',
    prefix: '$',
    suffix: '.00',
  });

  return (
    <div className='flex flex-col gap-y-[2.5rem] lg:gap-y-0 lg:grid grid-cols-12 gap-x-7 px-gutter py-[5rem] lg:py-[6.25rem] text-white text-center lg:text-left  '>
      <div className='col-start-1 col-span-7 w-full text-[1rem] lg:text-[1.75rem] leading-[120%]'>
        <p>
          From humble beginnings in 1874, Ponsonby Rugby Club has become the
          longest standing grassroots club in Auckland, producing a world-class
          junior rugby programme and more All Blacks than any other club in the
          country.
        </p>
        <br />
        <p>
          But we've had our share of challenges too - the Auckland Anniversary
          floods hit us hard last year, devastating the club rooms and
          facilities. It was tough, but the community rallied together and we're
          bouncing back.
        </p>
        <br />
        <p>
          So to keep momentum and celebrate an epic 150 years, we're throwing
          our biggest fundraiser ever! We've got some incredible prizes lined
          up, thanks to the amazing support from our loyal sponsors and donors -
          check them out below.
        </p>
        <br />
        <p>
          Your support means the world to us, seriously. The raffle has been
          drawn and all winners will be contacted individually. The club will
          also be posting on their social feeds. Thanks again for all your
          support.
        </p>
      </div>
      <div className='col-start-9 col-span-full flex flex-col gap-y-[2.5rem] lg:gap-y-0 lg:justify-between h-full items-center lg:items-start '>
        <div className=' flex flex-col font-obviously-narrow gap-y-[20px]'>
          <p className='text-[1.75rem] lg:text-[2.5rem] uppercase leading-[100%]'>
            Amount raised to date:
          </p>
          <p
            className='text-[4.5rem] lg:text-[5.75rem] leading-[100%]'
            ref={countUpRef}
          >
            {/* $
            {donationAmount.toLocaleString('nz-EN', {
              minimumFractionDigits: 2,
            })} */}
          </p>
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
